import {
  format,
  getTime,
  formatDistanceToNow,
  fromUnixTime,
  formatDistanceToNowStrict,
  addMinutes,
} from 'date-fns';
import * as Sentry from '@sentry/browser';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const getDateFromUnixTimestamp = (
  unixTimestamp: number | undefined,
): undefined | Date => {
  if (!unixTimestamp) return undefined;
  const timestampLength = unixTimestamp.toString().length;
  let date;
  try {
    if (timestampLength === 10) {
      // Unix timestamp in seconds, convert to milliseconds
      date = new Date(unixTimestamp * 1000);
    } else if (timestampLength === 13) {
      // Unix timestamp in milliseconds
      date = new Date(unixTimestamp);
    } else if (timestampLength === 16) {
      // Unix timestamp in microseconds, convert to milliseconds
      date = new Date(Math.floor(unixTimestamp / 1000));
    } else {
      Sentry.captureException(
        'Unknown Timestamp format. Timestamp is not in seconds, milliseconds or microseconds',
        {
          extra: {
            timestamp: unixTimestamp,
          },
        },
      );
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return date;
};
export const getLastScanTimeByTimestamp = (timestamp: number) => {
  return timestamp === 0
    ? '-'
    : formatDistanceToNow(fromUnixTime(timestamp), {
        addSuffix: true,
      });
};

/**
 * Converts the difference of input date and Now to a
 * short relative time difference string, such as
 * "in 2hrs", "3mo ago", "2yr ago" etc.
 *
 * We needed our own solution as the library methods use often
 * "quarter" as a unit which we do not want to use. Also we do
 * not want a space between the number and the unit.
 */
export function getRelativeTimeString(date: Date): string {
  var out = formatDistanceToNowStrict(date, { addSuffix: true });

  // remove space between number and unit
  out = out.replace(/\d+\s/, function (match) {
    return match.substring(0, match.length - 1);
  });

  // replace "seconds" with "sec"
  out = out.replace(/seconds?/, 'sec');

  // replace "minutes" with "min"
  out = out.replace(/minutes?/, 'min');

  // replace "hours" with "hr"
  out = out.replace(/hours?/, 'hr');

  // replace "days" with "d"
  out = out.replace(/days?/, 'd');

  // replace "months" with "mo"
  out = out.replace(/months?/, 'mo');

  // replace "years" with "yr"
  out = out.replace(/years?/, 'yr');

  return out;
}

export function getHumanReadableDateAndTime(
  unixTimestamp: number | undefined, //unix time
): string {
  if (!unixTimestamp) return '-';

  const date = getDateFromUnixTimestamp(unixTimestamp);

  return date
    ? `${format(addMinutes(date, date.getTimezoneOffset()), 'PPp')} UTC`
    : '-';
}
