import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Button, Dialog, IconButton } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

import * as Sentry from '@sentry/browser';
import { deleteTeamMember } from 'api/frontend';
import { TeamMember } from 'api/models';
import { toastAutoHideDuration } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { useNavigate } from 'react-router';
import styles from './RemoveTeamMemberConfirmationDialog.module.scss';

interface Props {
  member: TeamMember;
  open: boolean;
  onClose: () => void;
  callRefetch: VoidFunction;
}
const RemoveTeamMemberRequestError =
  'Something went wrong while removing this user. Please try again or escalate to Chkk';
const LeaveTeamRequestError =
  'Something went wrong while leaving this team. Please try again or escalate to Chkk';
export const RemoveTeamMemberConfirmationDialog = ({
  open,
  member,
  onClose,
  callRefetch,
}: Props) => {
  const { user, account, currentOrganization } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const message =
    user?.email === member?.user_email ? (
      `Are you sure you want to leave ${currentOrganization?.name}?`
    ) : (
      <>
        Are you sure you want to remove{' '}
        <span className="ph-no-capture">{member?.user_name}</span> from{' '}
        <span>{currentOrganization?.name}?</span>
      </>
    );

  const [loading, setLoading] = useState(false);

  const confirmRemoveMember = async () => {
    setLoading(true);
    logEvent('remove-team-member');
    try {
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };

      const response = await deleteTeamMember(
        'default',
        'default',
        member?.user_id,
        { headers },
      );

      if (response && response.status > 204) {
        onClose();
        enqueueSnackbar(
          user?.email === member?.user_email
            ? LeaveTeamRequestError
            : RemoveTeamMemberRequestError,
          {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          },
        );
        Sentry.captureException(RemoveTeamMemberRequestError, {
          extra: { response },
        });
      } else {
        onClose();
        callRefetch();
        enqueueSnackbar(
          user?.email === member?.user_email
            ? 'Left team successfully'
            : 'Team member removed successfully',
          {
            variant: 'success',
            autoHideDuration: toastAutoHideDuration,
          },
        );
        if (user?.email === member?.user_email) {
          navigate('/login');
        }
      }
      setLoading(false);
    } catch (error) {
      onClose();
      enqueueSnackbar(
        user?.email === member?.user_email
          ? LeaveTeamRequestError
          : RemoveTeamMemberRequestError,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error);
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content className="h-auto w-auto flex-none">
        <div className={styles['stack']}>
          <div className={styles['stack-d5c18b9f']}>
            <span className={styles['subheaderText']}>
              {user?.email === member?.user_email
                ? 'Leave Team'
                : 'Remove Team Member'}
            </span>
            <IconButton icon="FeatherX" size="medium" onClick={onClose} />
          </div>
          <div className={styles['stack-eb7f4307']}>
            <span className={styles['text']}>{message}</span>
          </div>
          <div className={styles['stack-bf3ad773']}>
            <Button
              size="large"
              id="remove-member-cancel"
              onClick={onClose}
              variant="neutral-tertiary"
              icon="FeatherX"
            >
              Cancel
            </Button>
            <Button
              id="remove-member-confirm"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={confirmRemoveMember}
              variant="destructive-primary"
              icon={
                user?.email === member?.user_email
                  ? 'FeatherLogOut'
                  : 'FeatherTrash2'
              }
            >
              {user?.email === member?.user_email ? 'Leave' : 'Remove'}
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
