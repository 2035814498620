'use client';
/* Release: 63880259 (Latest – unreleased) */

import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/components/TextField';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './CreateJiraDialog.module.scss';
import { SetStateFunction } from 'models/react';

import { useEffect, useState } from 'react';
import { SkeletonText } from 'subframe/index';
import {
  Cluster,
  ClusterDetectedLar,
  ClusterDetectedLarAffectedResource,
  CreateTicketRequest,
  IntegrationType,
  JiraCloudInfo,
  JiraCreateTicketDetails,
  JiraIssueType,
  JiraProject,
  LarAffectedResource,
} from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import {
  createTicket,
  useGetTicketDetails,
  useListIntegrations,
} from 'api/frontend';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { getCategory, splitStringAtUppercase } from 'utils/filters';
import { truncateAtLength } from 'utils/formatString';
import { AtomicTooltip } from 'components/design-system';
import * as Sentry from '@sentry/browser';
import { Select } from 'subframe/components/Select';

interface CreateJiraTicketFields {
  summary: string;
  description: string;
}

interface CreateJiraTicketProps {
  lar: ClusterDetectedLar;
  cluster: Cluster;
  handleOpen: SetStateFunction<boolean>;
  isOpen: boolean;
  mutateStatus: () => void;
  resources?: LarAffectedResource[] | undefined;
}

function CreateJiraTicketDialog({
  lar,
  cluster,
  handleOpen,
  isOpen,
  mutateStatus,
  resources,
}: CreateJiraTicketProps) {
  const { account, currentOrganization } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const [selectedCloud, setSelectedCloud] = useState<JiraCloudInfo>();

  const [selectedProject, setSelectedProject] = useState<JiraProject>();
  const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType>();
  let integId: string | undefined = undefined;
  const affectedResources:
    | ClusterDetectedLarAffectedResource[]
    | LarAffectedResource[] = resources
    ? resources
    : lar?.affected_resources?.length
    ? lar.affected_resources
    : lar?.resources;

  const description = `*What is the impact? (Availability Impact)*
${lar?.availability_impact}


*Category:* ${splitStringAtUppercase(getCategory(lar?.labels))}


${
  affectedResources && affectedResources.length
    ? '*Affected Resources:*' +
      affectedResources.slice(0, 3)?.map((rsc) => {
        const { kind, name, namespace } = rsc;

        return (
          '\n - *Name*: ' +
          name +
          '\n*Kind*: ' +
          kind +
          '\n*Namespace*: ' +
          namespace +
          `${
            rsc?.context?.image_tag
              ? `\n*Tags*: ${rsc?.context?.image_tag}`
              : ''
          }`
        );
      })
    : ''
}
${
  affectedResources?.length > 3
    ? ` - ... +[${affectedResources.length - 3} more|${
        import.meta.env.VITE_HOST_URL || window.location.origin
      }/orgs/${currentOrganization?.id}/accounts/${
        account?.accountId
      }/clusters/${cluster.id}/lar/${lar.signature_id.toLowerCase()}]`
    : ''
}


*Details:*
- *Cluster Name:*  ${cluster.name}

- *Cluster ID:*  ${cluster.id}

- *Cluster Type:*  ${cluster.internal_k8s_ref}

- *Cluster Version:*  ${cluster.version}

- *LAR Details:* [${lar.signature_id}|${
    import.meta.env.VITE_HOST_URL || window.location.origin
  }/orgs/${currentOrganization?.id}/accounts/${account?.accountId}/clusters/${
    cluster.id
  }/lar/${lar.signature_id.toLowerCase()}]
`;

  const CreateTicketSchema = Yup.object().shape({
    summary: Yup.string().max(255).required('Summary is required'),
    description: Yup.string().max(32767).optional(),
  });

  const defaultValues: CreateJiraTicketFields = {
    summary: lar.title,
    description: description,
  };
  const methods = useForm<CreateTicketRequest>({
    resolver: yupResolver(CreateTicketSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  useEffect(() => {
    setValue('description', description);
  }, [description]);

  if ((!isOpen && errors?.summary) || errors?.description) {
    clearErrors();
  }

  const { data: integrationsResp, error: integrationsError } =
    useListIntegrations({
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    });

  if (
    integrationsResp &&
    integrationsResp.data?.some(
      (i) =>
        i.integration_type === IntegrationType.jira_app &&
        i.status === 'complete',
    )
  ) {
    if (!integId)
      integrationsResp.data?.filter((i) => {
        if (i.integration_type === IntegrationType.jira_app) {
          integId = i.integration_id;
        }
      });
  }

  const { data: ticketDetailsResponse, error } = useGetTicketDetails(
    integId || '',
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  if (
    //verifies if Jira integration is complete
    integrationsResp &&
    !integrationsResp.data?.some(
      (i) =>
        i.integration_type === IntegrationType.jira_app &&
        i.status === 'complete',
    )
  ) {
    if (isOpen) {
      handleOpen(false);
      enqueueSnackbar(
        'No Jira Integration found. Please integrate Jira first',
        {
          variant: 'error',
        },
      );
      return <></>;
    }
  } else if (error && isOpen) {
    //verifies if there is an error in fetching Jira ticket details
    handleOpen(false);
    enqueueSnackbar(
      somethingWentWrong.replace('<action>', 'fetching ticket details'),
      {
        variant: 'error',
      },
    );
    Sentry.captureException(error);
    return <></>;
  }

  const clouds: JiraCloudInfo[] =
    ticketDetailsResponse?.jira_details?.clouds || [];

  const onSubmit = async (data: CreateJiraTicketFields) => {
    try {
      if (!isValid) {
        return;
      }
      logEvent('create-ticket-submit', {
        clusterId: cluster.id,
        larId: lar.signature_id,
        jiraProject: selectedProject?.name,
        issueType: selectedIssueType,
      });

      const jiraDetails: JiraCreateTicketDetails = {
        project_id: selectedProject?.id || '',
        cloud_id: selectedCloud?.id || '',
        issue_type_id: selectedIssueType?.id || '',
      };
      const reqBody: CreateTicketRequest = {
        jira_details: jiraDetails,
        integration_id: integId || '',
        lar_id: lar.signature_id,
        cluster_id: cluster.id,
        description: data.description,
        summary: data.summary,
      };

      const response = await createTicket(reqBody, {
        headers: { Authorization: `Bearer ${account.token}` },
      });

      enqueueSnackbar('Jira Ticket created successfully', {
        variant: 'success',
      });
      mutateStatus();
    } catch (error) {
      if (error?.response?.status === 400 && error?.response?.data?.message) {
        enqueueSnackbar(
          `Chkk received an error from JIRA while creating this ticket: <action> Chkk support team will be in touch with you to resolve this issue`.replace(
            '<action>',
            error?.response?.data?.message,
          ),
          {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          },
        );
      } else {
        enqueueSnackbar(
          somethingWentWrong.replace('<action>', 'creating this ticket'),
          {
            variant: 'error',
          },
        );
      }
      Sentry.captureException(error, {
        extra: { data },
      });
    }
    handleOpen(false);
  };

  const resetToDefault = () => {
    methods.setValue('summary', lar?.title);
    setSelectedIssueType(undefined);
    setSelectedProject(undefined);
    setSelectedCloud(undefined);
    methods.setValue('description', description);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        handleOpen(!isOpen);
        resetToDefault();
      }}
    >
      <Dialog.Content>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['stack-a654c328']}>
            <div className={styles['stack-5c53ee0b']}>
              <span className={styles['text-c4c4eca0']}>Create Ticket</span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={() => {
                  handleOpen(false);
                  resetToDefault();
                }}
              />
            </div>
            <div className={styles['stack-f88a128f']}>
              <div className={styles['stack-4b97e9d1']}>
                <span className={styles['text-321f1607']}>Site</span>
                {ticketDetailsResponse ? (
                  <AtomicTooltip
                    side="top"
                    align="end"
                    tooltipContent={selectedCloud?.name || 'Select Site'}
                  >
                    <Select
                      className="h-10 w-full max-w-[176px] flex-none"
                      disabled={!clouds || isSubmitting}
                      placeholder={truncateAtLength(
                        selectedCloud?.name || 'Select Site',
                        16,
                      )}
                      onValueChange={(value) => {
                        const cloud = clouds.find(
                          (c) => c.id === String(value),
                        );
                        if (cloud) {
                          setSelectedIssueType(undefined);
                          setSelectedProject(undefined);
                          setSelectedCloud(cloud);
                        }
                      }}
                    >
                      {clouds?.map((cloud) => (
                        <Select.Item key={cloud.id} value={cloud.id}>
                          {cloud.name}
                        </Select.Item>
                      ))}
                    </Select>
                  </AtomicTooltip>
                ) : (
                  <SkeletonText className="flex-[0_0_auto] h-[40px] w-full" />
                )}
              </div>
              <div className={styles['stack-3ace6d27']}>
                <span className={styles['text']}>Project</span>
                {ticketDetailsResponse ? (
                  <AtomicTooltip
                    side="top"
                    align="end"
                    tooltipContent={
                      selectedProject?.name ||
                      `${
                        !selectedCloud ? 'Select Site First' : 'Select Project'
                      }`
                    }
                  >
                    <Select
                      className="h-10 w-full max-w-[176px] flex-none"
                      disabled={!selectedCloud || isSubmitting}
                      placeholder={truncateAtLength(
                        selectedProject?.name ||
                          `${
                            !selectedCloud
                              ? 'Select Site First'
                              : 'Select Project'
                          }`,
                        16,
                      )}
                      onValueChange={(value) => {
                        const project = selectedCloud?.projects?.find(
                          (p) => p.id === String(value),
                        );
                        if (project) {
                          setSelectedIssueType(undefined);
                          setSelectedProject(project);
                        }
                      }}
                    >
                      {selectedCloud ? (
                        selectedCloud.projects.map((project) => (
                          <Select.Item key={project.id} value={project.id}>
                            {project.name}
                          </Select.Item>
                        ))
                      ) : (
                        <Select.Item value="" disabled>
                          Select Site First
                        </Select.Item>
                      )}
                    </Select>
                  </AtomicTooltip>
                ) : (
                  <SkeletonText className="flex-[0_0_auto] h-[40px] w-full" />
                )}
              </div>
              <div className={styles['stack']}>
                <span className={styles['text-2f38c9d3']}>Issue Type</span>
                {ticketDetailsResponse ? (
                  <AtomicTooltip
                    side="top"
                    align="end"
                    tooltipContent={
                      selectedIssueType?.name ||
                      `${
                        !selectedCloud
                          ? 'Select Site First'
                          : !selectedProject
                          ? 'Select Project First'
                          : 'Select Issue Type'
                      }`
                    }
                  >
                    <Select
                      className="h-10 w-44 flex-none"
                      disabled={!selectedProject || isSubmitting}
                      placeholder={truncateAtLength(
                        selectedIssueType?.name ||
                          `${
                            !selectedCloud
                              ? 'Select Site First'
                              : !selectedProject
                              ? 'Select Project First'
                              : 'Select Issue Type'
                          }`,
                        16,
                      )}
                      onValueChange={(value) => {
                        const issue_type = selectedProject?.issue_types?.find(
                          (i) => i.id === String(value),
                        );
                        if (issue_type) {
                          setSelectedIssueType(issue_type);
                        }
                      }}
                    >
                      {selectedProject ? (
                        selectedProject.issue_types.map((issue_type) => (
                          <Select.Item
                            key={issue_type.id}
                            value={issue_type.id}
                          >
                            {issue_type.name}
                          </Select.Item>
                        ))
                      ) : (
                        <Select.Item value="" disabled>
                          Select Project First
                        </Select.Item>
                      )}
                    </Select>
                  </AtomicTooltip>
                ) : (
                  <SkeletonText className="flex-[0_0_auto] h-[40px] w-full" />
                )}
              </div>
            </div>
            <TextField
              className="h-full w-full grow shrink-0 basis-0"
              label="Summary"
              helpText={errors.summary?.message}
              error={methods.getFieldState('summary').invalid}
            >
              <TextField.Input
                placeholder={lar.title || 'Enter Summary'}
                disabled={isSubmitting}
                {...methods.register('summary')}
              />
            </TextField>
            <TextArea
              className="h-full w-full grow shrink-0 basis-0"
              label="Description"
              helpText={errors.description?.message}
              error={false}
            >
              <TextArea.Input
                className="h-24 w-full flex-none"
                placeholder={description || 'Enter Description'}
                disabled={isSubmitting}
                {...methods.register('description')}
              />
            </TextArea>
            <div className={styles['stack-a2044f34']}>
              <Button
                size="large"
                variant="brand-primary"
                icon={null}
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                disabled={
                  !!integrationsError ||
                  isSubmitting ||
                  !(selectedCloud && selectedIssueType && selectedProject)
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}

export default CreateJiraTicketDialog;
